import { FC, useEffect } from "react";
import { useRouter } from "next/router";
import * as gtm from "src/utils/gtm";

export const GoogleTagManager: FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", gtm.pageview);
    return () => {
      router.events.off("routeChangeComplete", gtm.pageview);
    };
  }, [router.events]);

  return children as React.ReactElement;
};
