import React from "react";
import "../../styles/globals.css";
import { AppProps } from "next/app";
import Head from "next/head";
import { DehydratedState, QueryClient, Hydrate, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GoogleTagManager } from "src/components/google-tag-manager";

const queryClient = new QueryClient();

const App: React.FC<
  AppProps<{
    dehydratedState: DehydratedState;
  }>
> = ({ Component, pageProps }) => {
  return (
    <GoogleTagManager>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <title>Iconic House</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Component {...pageProps} />
        </Hydrate>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </GoogleTagManager>
  );
};

export default App;
